.site-footer{

	.newsletter{
		color:$corange;
		background-color:white;
		width:100%;
		padding-bottom: 75px;
		@media (max-width: 650px ) {
			padding-bottom: 50px;
		}
	}
	.footerimage {
		background-image: url("./img/footer-mountains.jpg");
		height: 600px;
		background-size: cover;
		background-position: center top;
		@media (max-width: 991px) {
			height: 400px;
		}
		@media (max-width: 767px) {
			height: 300px;
		}
	}
	a, p{
		display:inline-block;
		width:auto;
	}

	a, p{
		color:black;
	}

	.site-info{

		background-color:white;
		padding:90px 0px 50px 0px;
		display: flex;
		justify-content:space-between;
		@media (max-width: 991px) {
			padding-top: 40px;
			display: block;
		}
	}

	.newsletter{
		border:$cgrey;
		border-style:solid;
		/*border-right: transparent;
		border-left:transparent;*/
		border-width:1px 0px 1px 0px;

		h1{
			text-align: center;
			font-family: 'Roboto Condensed', sans-serif;
			font-weight: 700;
			padding-bottom:15px;
			margin-bottom: 0px;
			margin-top:0px;
			padding-top: 70px;
			font-size: 32px;
			@media (max-width: 490px ) {
				font-size: 27px;
				padding-top: 50px;
			}
		}

		.underline{
			width: 70px;
			height: 6px;
			background-color:$cblue2;
			margin:0px auto 0px auto;
			margin-bottom: 50px;
			@media (max-width: 650px) {
				margin-bottom: 75px;
			}
		}
		.widget_wysija_cont.shortcode_wysija {
			.wysija-msg.ajax {
				.allmsgs {
					blink {
						display: none;
					}
				}
				.updated {
					background-color: transparent;
					border-color: transparent;
					ul {
						li {
							font-size: 18px;
							text-align: center;
							font-weight: 700;
						}
					}
				}
				.error {
					ul {
						text-align: center;
						font-size: 18px;
						font-weight: 700;
						&:after {
							content: "Wystąpił problem, przepraszamy i prosimy spróbować ponownie później.";
						}
						li {
							display: none;
							a {
								display: none;
							}
						}
					}
				}
			}
		}
		form {
			&.shortcode_wysija.widget_wysija {
				height: 40px;
				display: flex;
				justify-content: center;
				@media (max-width: 650px) {
					flex-direction: column;
					height: auto;
					align-items: center;
				}
				.wysija-submit {
					&.wysija-submit-field {
						margin: 0;
						border-radius: 0;
				    background-color: inherit;
				    color: inherit;
				    transition: all .5s;
				    display: block;
				    font-size: 14px;
				    font-weight: 700;
				    padding-left: 15px;
				    padding-right: 15px;
				    padding-top: 7px;
				    padding-bottom: 8px;
				    width: 110px;
						@media (max-width: 650px) {
							margin-top: 30px;
						}
				    &:focus {
				      outline: 0;
				    }
						border-width: 0px;
		        box-shadow: 0px 0px 0px 1.5px rgba(239,128,30,1);
		        &:hover {
		          color: #FFF;
		          background-color: $corange;
		        }
		        &:active {
		          background-color: $corange;
		          border-color: $corange;
		          color: #FFF;
		        }
					}
				}
				.wysija-paragraph {
					input {
						&:active, &:focus {
							outline: none;
						}
						&::placeholder {
							color: #a2a2a2;
						}
						color: #a2a2a2;
						&:active, &:focus {
							color: black;
						}
						font-size: 18px;
						width: 400px;
						margin-right: 25px;
						height: 40px;
						border: 0;
						border-bottom: 2px solid $corange;
						@media (max-width: 650px) {
							text-align: center;
							margin-right: 0;
							padding-bottom: 5px;
						}
						@media (max-width: 490px) {
							width: 100%;
						}
					}
					@media (max-width: 490px) {
						width: 100%;
					}
				}
			}
		}
		.invalid-mail {
			margin-top: 20px;
			text-align: center;
			p {
				color: red;
			}
			&.hidde {
				display: none;
			}
		}
	}

	.formErrorContent {
		display: none;
	}


	.regulations{
		align-self:center;
		cursor: pointer;
		@media (max-width: 991px) {
			text-align: center;
		}
		a{
			color:$cblue2;
			font-weight:600;
		}
		.regulations-link{
			color:$cblue2;
			font-weight:600;

		}
	}

	.icons {
		align-self:center;
		display: flex;
		justify-content: center;
		@media (max-width: 991px) {
			padding-bottom: 40px;
		}
		img {
			max-height: 50px;
			margin:10px;
			margin-right: 20px;
			margin-left: 20px;
			background-color: transparent;
			-webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.2));
 			filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.2));
		}
	}


	.policy{
		align-self:center;
		display: flex;
		justify-content: flex-end;
		@media (max-width: 991px) {
			justify-content: center;
		}
		.policy-links{
			/*display: flex;
			justify-content: right;*/
			text-align:right;
			p{
				padding:5px;
				color:$cdarkgrey;
			}
			a{
				cursor: pointer;
				color:$cdarkgrey;
			}
		}
	}









	.overlay {
	    position: fixed;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    z-index: 10000000;
	    overflow-y: hidden;
	    background-color: #000;
	    opacity: 0;
	    top: 0px;
	}


	.cookies-detail,.policy-detail {
		position: fixed;
		z-index: 1000000111111;
		opacity: 0;
		top:0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: white;
		overflow-y: auto;
		overflow-x: hidden;
		padding-top: 50px;
		.exit {
			max-height: 22.5px;
			position: fixed;
			right: 20px;
			top: 20px;
			z-index: 10000002;
			cursor: pointer;
			&.policyexit {
				right: 40px;
			}
		}
		h1 {
			color: $cblue2;
			font-weight: 700;
			padding-bottom: 10px;
		}
		p {
			text-align: justify;
			padding-bottom: 10px;
		}
		.bottom-padding {
			padding-bottom: 50px;
		}
	}

   .regulations-details {
		 .line-break {
			 display: none;
			 @media (max-width: 500px) {
				 display: block;
			 }
		 }
	 		position: fixed;
	    z-index: 1000000111111;
			opacity: 0;
	    top:0;
	    left: 0;
	    right: 0;
			bottom: 0;
			background-color: white;
			overflow-y: auto;
			overflow-x: hidden;
			h1{
				color:$cblue2;
				font-size: 52px;
				text-align: center;
				font-family: 'Roboto Condensed', sans-serif;
				font-weight: 700;
				padding-top:50px;
				padding-bottom:20px;
				margin-bottom: 0px;
				margin-top:0px;
				@media (max-width: 600px) {
					font-size: 42px;
				}
			}
			.exit {
				max-height: 22.5px;
				position: absolute;
				right: 20px;
				top: 20px;
				z-index: 10000002;
				cursor: pointer;
			}
			.underline{
				width: 76px;
				height: 8px;
				background-color:$corange;
				margin:0px auto 60px auto;
			}
			a {
				margin-top: 25px;
				display: block;
				width: 615px;
				margin: 12.5px auto 12.5px auto;
				@media (max-width: 767px) {
					width: 85%;
				}
			}
			.download {
				width: 615px;
				margin: 0 auto;
				box-shadow: 2px 3px 9.7px 0.3px rgba(0, 0, 0, 0.2);
				padding: 15px 30px 15px 30px;
				@media (max-width: 767px) {
					width: 100%;
				}
				@media (max-width: 600px) {
					padding: 15px;
				}
				p {
					font-size: 16px;
					color: $cblue2;
					margin-bottom: 0;
					@media (max-width: 600px) {
						font-size: 14px;
					}
				}
				img {
					max-height: 21px;
					float: right;
					@media (max-width: 500px) {
						margin-top: 10px;
					}
				}
			}
 	}



}

.janusz {
	.newsletter {
		display: none;
	}
}
