@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: url('./img/leftButton.png') !default;
$slick-next-character: url('./img/rightButton.png') !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
}

/* Icons */
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 35px;
    width: 35px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: $cblue2;
    color: #FFF;
    top: 47.5%;
    -webkit-transform: translate(0, -46%);
    -ms-transform: translate(0, -46%);
    transform: translate(0, -46%);
    padding: 0;
    border-radius: 30px;
    border: 0.1px solid $cblue2;
    outline: none;
    z-index: 1000002;
    &:hover, &:focus {
        outline: none;
        background: $cblue2;
        color: #FFF;
        &:before {
            //opacity: $slick-opacity-on-hover;
        }
    }
    &.slick-disabled:before {
        //opacity: $slick-opacity-not-active;
    }
    &:before {
        font-family: $slick-font-family;
        font-size: 20px;
        line-height: 1;
        color: $cblue2;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.slick-prev {
    left: 25px;
    [dir="rtl"] & {
        left: auto;
        right: -25px;
    }
    &:before {
        padding-right: 3.5px;
        content: $slick-prev-character;
        [dir="rtl"] & {
            content: $slick-next-character;
        }
    }
}

.slick-next {
    right: 25px;
    [dir="rtl"] & {
        left: -25px;
        right: auto;
    }
    &:before {
        content: $slick-next-character;
        [dir="rtl"] & {
            content: $slick-prev-character;
        }
    }
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0;
        padding: 0;
        cursor: pointer;
        button {
            border: 1px solid $cblue2;
            border-radius: 10px;
            background: transparent;
            display: block;
            height: 10px;
            width: 10px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            &:hover, &:focus {
                background-color: $cblue2;
                &:before {
                    background-color: $cblue2;
                }
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                background-color: $cblue2;
                line-height: 20px;
                text-align: center;
                opacity: $slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
        &.slick-active {
            button {
              background-color: $cblue2;
            }
        }
    }
}
