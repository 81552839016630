header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  color: white;
  background-color: $cblue;
  border-bottom: 7px solid $corange;
  box-shadow: 0px 11px 27.84px 4.16px rgba(51, 51, 51, 0.2);
  z-index: 10000001;
  transition: top 0.2s ease-in-out;
  &.nav-up {
    top: -82px;
    @media (max-width: 991px) {
      top: -510px;
    }
  }
  .row {
    @media (max-width: 991px) {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .logo {
    max-height: 55px;
    padding-top: 20px;
    @media (max-width: 991px) {
      margin: 0 auto;
      padding-top: 0;
      max-height: 35px;
    }
  }
  .menu {
    height: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
    font-weight: 700;
    @media (max-width: 991px) {
      display: block;
      margin-top: 100px;
      height: auto;
    }
    li {
      padding-top: 5px;
      display: inline;
      @media (max-width: 991px) {
        padding-top: 0;
        display: block;
        text-align: center;
        height: 70px;
        line-height: 70px;
      }
      a {
        font-size: 16px;
        color: white;
        transition: color 0.3s;
        @media (max-width: 991px) {
          display: block;
          height: 100%;
          width: 100%;
        }
        &:hover {
          color: $corange;
          text-decoration: none;
          transition: color 0.3s;
        }
      }
      &.active {
        @media (max-width: 991px) {
          background-color: #1f5991;
        }
        a {
          color: $corange;
        }
      }
    }
  }
  .contact-div {
    align-items: center;
    justify-content: flex-end;
    display: flex;
    height: 75px;
    span {
      margin-right: 40px;
      font-size: 16px;
      a {
        color: #fff;
        transition: all 0.3s;
        text-decoration: none;
        &:hover {
          transition: all 0.3s;
          color: $corange
        }
      }
    }
    .contact-img {
      cursor: pointer;
      position: relative;
      align-self: center;
      height: 40px;
    }
    .contact-info {
      @media (max-width: 1999px) {
        left: -72px;
      }
      @media (max-width: 1200px) {
        left: -106px;
      }
      padding-bottom: 20px;
      padding-left: 35px;
      padding-right: 30px;
      top: 75px;
      position: absolute;
      background-color: $corange;
      left: -72px;
      z-index: 10;
      h6 {
        margin-top: 30px;
        font-weight: 700;
        font-size: 16px;
      }
      a {
        display: block;
        color: white;
        font-weight: 500;
        margin-left: 15px;
      }
      .little-triangle {
        top:-10px;
        right: 8px;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 12px 10px;
        border-color: transparent transparent $corange transparent;
      }
    }
  }
  .burger-container {
    display: flex;
    align-items: center;
    height: 35px;
    padding-left: 15px;
    padding-right: 0;
  }
  .burger-menu {
    @media (min-width: 768px) {
      display: block;
    }
    &.navbar-toggled {
      padding: 0;
      .icon-bar {
        background-color: white;
        height: 3px;
        width: 30px;
        margin-top: 6px;
        &:first-of-type {
          margin-top: 0;
        }
      }
    }
    height: 20px;
    float: left;
    margin: 0;

  }
  .expanded-burger {
    padding-bottom: 30px;
    &.hidde {
      display: none;
    }
    box-shadow: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20000002;
    background-color: #004282;
    border-bottom: 5px solid $corange;
    box-shadow: 0px 11px 27.84px 4.16px rgba(51, 51, 51, 0.2);
    .exit-burger {
      position: absolute;
      top: 30px;
      left: 20px;
      z-index: 20000003;
    }
    .english {
      position: absolute;
      right: 20px;
      top: 28px;
      font-size: 16px;
      color: White;
    }
  }
  .logo-mobile {
    @media (max-width: 400px) {
      padding: 0;
    }
  }
  .contact-mobile {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  //  height: 75px;
    @media (max-width: 400px) {
      padding-left: 0;
    }
    img {
      height: 35px;
    }
  }
  .contact-mobile-container {
    //opacity: 0;
    &.hidde {
      display: none;
    }
    height: 500px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20000002;
    background-color: $corange;
    color: white;
    border-bottom: 5px solid $cblue2;
    box-shadow: 0px 11px 27.84px 4.16px rgba(51, 51, 51, 0.2);
    text-align: center;
    h6 {
      text-align: center;
      margin-top: 100px;
      font-weight: 700;
      font-size: 27px;
      margin-bottom: 25px;
    }
    a {
      color: white;
      font-weight: 500;
      font-size: 21px;
      text-align: center;
    }
    .exit-contact {
      position: absolute;
      top: 30px;
      right: 22px;
      z-index: 20000003;
    }
  }
}
