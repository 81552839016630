.for_business-site {
  margin-top: 162px;
  @media (max-width: 991px) {
    margin-top: 140px;
  }
  p {
    text-align: justify;
    line-height: 24px;
    margin-top: 20px;
    font-size: 14px;
  }
  img {
    &.shadowed {
      -webkit-box-shadow: 8px 10px 10px -4px rgba(230,229,229,1);
      -moz-box-shadow: 8px 10px 10px -4px rgba(230,229,229,1);
      box-shadow: 8px 10px 10px -4px rgba(230,229,229,1);
      @media (max-width: 1199px) {
        max-height: 260px;
      }
      margin: 0 auto;
      @media (max-width: 767px) {
        margin: 0;
      }
    }

  }
  .btn-outline {
    border-radius: 0;
    margin: 35px auto;
    background-color: inherit;
    color: inherit;
    transition: all .5s;
    display: block;
    font-size: 13px;
    font-weight: 700;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 8px;
    width: 205px;
    border: 0px;
    &:focus {
      outline: 0;
    }
    @media (max-width: 767px) {
      margin: 35px 0;
    }
  }
  .section-one {
    margin-bottom: 80px;
    @media (max-width: 767px) {
      margin-bottom: 0px;
    }
  }
  .first-text-section {
    h1 {
      font-family: 'Roboto Condensed', sans-serif;
      position: relative;
      color: $cblue2;
      font-weight: 700;
      font-size: 60px;
      margin-bottom: 80px;
      @media (max-width: 500px) {
				font-size: 50px;
        margin-bottom: 60px;
			}
			@media (max-width: 400px) {
				font-size: 32px;
        margin-bottom: 40px;
			}
    }
    h2 {
      font-family: 'Roboto Condensed', sans-serif;
      position: absolute;
      top: -25px;
      left: 15px;
      font-size: 80px;
      font-weight: 700;
      color: $cblue2;
      opacity: 0.05;
      @media (max-width: 991px) {
        font-size: 75px;
      }
      @media (max-width: 767px) {
        font-size: 80px;
        top: -45px;
      }
      @media (max-width: 500px) {
        font-size: 66px;
      }
      @media (max-width: 400px) {
        font-size: 50px;
      }
    }
    .underline {
      top: 100px;
      position: absolute;
      width: 70px;
      border-bottom: 8px solid $corange;
      @media (max-width: 767px) {
        top: 80px;
      }
      @media (max-width: 500px) {
				border-bottom: 7px solid $corange;
				width: 60px;
        top: 65px;
			}
			@media (max-width: 400px) {
				border-bottom: 5px solid $corange;
				width: 50px;
        top: 45px;
			}
    }
  }
  .first-photo-section {
    padding-top: 30px;
    @media (max-width: 991px) {
      padding-left: 20px;
    }
    img {
      border-bottom: 15px solid $cblue2;
    }
    .btn-primary {
      color: $cblue2;
      box-shadow: 0px 0px 0px 1.5px $cblue2;
      &:hover {
        color: #FFF;
        background-color: $cblue2;
      }
      &:active {
        color: #FFF;
      }
    }
  }
  .seciton-two {
    margin-bottom: 60px;
    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }
  .second-photo-section {
    padding-top: 6px;
    @media (max-width: 991px) {
      padding-right: 20px;
    }
    @media (max-width: 767px) {
      padding-top: 40px;
    }
    img {
      border-bottom: 15px solid $cblue2;
    }
    .btn-primary {
      color: $cblue2;
      box-shadow: 0px 0px 0px 1.5px $cblue2;
      &:hover {
        color: #FFF;
        background-color: $cblue2;
      }
      &:active {
        color: #FFF;
      }
    }
  }
  .second-text-section {
    @media (min-width: 768px) {
      p:first-of-type {
        margin-top: 0;
      }
    }
  }
  .section-three {
    margin-bottom: 60px;
  }
  .third-text-section {
    @media (min-width: 768px) {
      p:first-of-type {
        margin-top: 0;
      }
    }
    @media (max-width: 767px) {
      padding-top: 0;
    }
  }
  .third-photo-section {
    padding-top: 5px;
    @media (max-width: 767px) {
      padding-top: 40px;
    }
    img {
      border-bottom: 15px solid $cblue2;
    }
    .btn-primary {
      color: $cblue2;
      box-shadow: 0px 0px 0px 1.5px $cblue2;
      &:hover {
        color: #FFF;
        background-color: $cblue2;
      }
      &:active {
        color: #FFF;
      }
    }
  }
  .text-at-the-end {
    margin: 0 auto;
    max-width: 700px;
    text-align: center;
    margin-bottom: 80px;
    p {
      text-align: center;
      font-weight: 600;
    }
  }
  .question-stripe {
    cursor: pointer;
    text-align: center;
    font-family: 'Roboto Condensed', sans-serif;
    background-color: $cblue2;
    padding-top: 10px;
    padding-bottom: 15px;
    margin-bottom: 80px;
    a {
      color: #fff;
      &:hover {
        text-decoration: none;
      }
    }
    @media (max-width: 850px) {
      text-align: left;
    }
    @media (max-width: 500px) {
      margin-bottom: 50px;
    }
    h1 {
      font-weight: 700;
      color: #FFF;
      display: inline-block;
      @media (max-width: 850px) {
        padding-left: 20px;
      }
      @media (max-width: 560px) {
        font-size: 30px;
      }
      @media (max-width: 440px) {
        font-size: 24px;
      }
      @media (max-width: 350px) {
        font-size: 20px;
      }
    }
    img {
      display: inline-block;
      max-height: 35px;
      margin-bottom: 20px;
      margin-left: 40px;
      box-shadow: none;
      @media (max-width: 850px) {
        margin: 0;
        max-height: 60px;
        float: right;
        margin-top: 29px;
      }
      @media (max-width: 560px) {
        margin-top: 26px;
        max-height: 50px;
      }
      @media (max-width: 440px) {
        max-height: 35px;
      }
      @media (max-width: 350px) {
        max-height: 30px;
        margin-top: 28px;
      }
    }
    .line-break {
      display: none;
      @media (max-width: 850px) {
        display: block;
      }
    }
  }
  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000000;
    overflow-y: hidden;
    background-color: #000;
    opacity: 0;
    top: 0px;
  }
  .detal {
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0;
    background-color: #FFF;
    position: fixed;
    z-index: 10000001;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    &.component-detal {
      .header {
        padding-top: 15px;
        padding-bottom: 13px;
        color: white;
        background-color: $cblue2;
        font-weight: 700;
        position: fixed;
        left: 0;
        right: 0;
        z-index: 2000000000;
        .exit {
          img {
            cursor: pointer;
            float:right;
          }
        }
      }
      .slider {
        margin-top: 110px;
        .slick-slide {
          margin-right: 10px;
          margin-left: 10px;
        }
        .slick-track {
          padding-bottom: 10px;
        }
        .slick-dots {
          li {
            width: 12px;
            margin-right: 2.5px;
            margin-left: 2.5px;
          }
          bottom: -35px;
        }
        .slick-arrow {
          padding-bottom: 2px;
        }
      }
      .text-row {
        margin-bottom: 30px;
        margin-top: 100px;
        .text-col {
          margin: 0 auto;
          display: flex;
          width: 70%;
          justify-content: space-around;
          @media (max-width: 991px) {
            width: 100%;
            padding-left: 50px;
          }
          @media (max-width: 600px) {
    				flex-direction: column;
    			}
          .plain-text {
            width: 200px;
            margin-left: 20px;
            margin-right: 20px;
            position: relative;
            @media (max-width: 600px) {
      				max-width: 100%;
      				margin-top: -10px;
      			}
            p {
      				max-width: 150px;
      				font-size: 16px;
      				@media (max-width: 600px) {
      					max-width: 100%;
      				}
      			}
          }
          .hyphen-before {
            position: absolute;
            height: 12px;
            width: 9px;
            left: -25px;
            border-bottom: 3px solid $cblue2;
          }
          p {
            position: relative;
            margin-top: 10px;
          }
        }
      }
      .footer {
        padding-top: 15px;
        padding-bottom: 13px;
        background-color: $cblue2;
        color: white;
        font-weight: 700;
        @media (max-width: 420px) {
          position: relative;
        }
        .leftText {
          @media (max-width: 370px) {
            padding-right: 0;
            padding-left: 10px;
          }
          cursor: pointer;
        }
        .rightText {
          @media (max-width: 370px) {
            padding-left: 0;
            padding-right: 10px;
          }
          cursor: pointer;
          text-align: right;
        }
        .arrow {
          display: inline-block;
          @media (max-width: 420px) {
            position: absolute;
          }
          &.left-arrow {
            padding-bottom: 1px;
            margin-right: 10px;
            @media (max-width: 420px) {
              left: 0;
              top: 3px;
            }
            @media (max-width: 370px) {
              left: -5px;
            }
          }
          &.right-arrow {
            margin-left: 10px;
            float: right;
            padding-top: 3px;
            @media (max-width: 420px) {
              right: 0;
              top: 0px;
            }
            @media (max-width: 370px) {
              right: -5px;
            }
          }
        }
      }
    }
  }
}
