.for-you-site {
  //Some "global" styling for this site ;)
  margin-top: 82px;
  .btn-outline {
    border-radius: 0;
    background-color: inherit;
    color: inherit;
    transition: all .5s;
    display: block;
    font-size: 13px;
    font-weight: 700;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 8px;
    width: 205px;
    &:focus {
      outline: 0;
    }
  }
  h1 {
    font-family: 'Roboto Condensed', sans-serif;
    position: relative;
    font-weight: 700;
    font-size: 48px;
  }
  h2 {
    font-family: 'Roboto Condensed', sans-serif;
    position: absolute;
    font-size: 80px;
    font-weight: 700;
    opacity: 0.05;
  }
  p {
    text-align: justify;
    line-height: 24px;
    margin-top: 30px;
    font-size: 14px;
  }
  .shadowed {
    -webkit-box-shadow: 8px 10px 10px -4px rgba(230,229,229,1);
    -moz-box-shadow: 8px 10px 10px -4px rgba(230,229,229,1);
    box-shadow: 8px 10px 10px -4px rgba(230,229,229,1);
  }

  .boxes {
    margin-top: 5px;
    margin-bottom: 10px;
    div {
      display: inline-block;
      height: 12px;
      width: 25px;
      background-color: $cdarkgrey;
      &.lv0 {
        background-color: $cgrey;
      }
      &.lv1 {
        background: linear-gradient(45deg, rgba(0,200,83,1) 0%, rgba(86,200,53,1) 100%);
      }
      &.lv2 {
        background: linear-gradient(45deg, rgba(100,200,48,1) 0%, rgba(211,200,11,1) 100%);
      }
      &.lv3 {
        background: linear-gradient(45deg, rgba(222,200,8,1) 0%, rgba(255,170,4,1) 100%);
      }
      &.lv4 {
        background: linear-gradient(45deg, #ffa207 0%, #f76421 100%);
      }
      &.lv5 {
        background: linear-gradient(45deg, #f45e24 0%, #e53935 100%);
      }
    }
  }
  ///////////////////////////////////////
  .section-one {
    margin-top: 80px;
    @media (max-width: 991px) {
      margin-top: 50px;
    }
    @media (max-width: 767px) {
      margin-top: 20px;
    }
    .first-text-section {
      margin-top: 50px;
      h1 {
        color: $corange;
        margin-bottom: 75px;
        font-size: 60px;
        @media (max-width: 500px) {
          font-size: 50px;
          margin-bottom: 60px;
        }
        @media (max-width: 400px) {
          font-size: 36px;
          margin-bottom: 40px;
        }
      }
      h2 {
        top: -25px;
        left: 12px;
        color: $corange;
        @media (max-width: 991px) {
          top: -45px;
        }
        @media (max-width: 500px) {
          font-size: 65px;
          top: -40px;
        }
        @media (max-width: 400px) {
          font-size: 50px;
        }
      }
      .underline {
        top: 100px;
        position: absolute;
        width: 70px;
        border-bottom: 7px solid $cblue2;
        @media (max-width: 991px) {
          top: 80px;
        }
        @media (max-width: 500px) {
  				border-bottom: 6px solid $cblue2;
  				width: 60px;
          top: 65px;
  			}
  			@media (max-width: 400px) {
  				border-bottom: 5px solid $cblue2;
  				width: 50px;
          top: 45px;
  			}
      }
      .bold-paragraph {
        font-weight: 700;
      }
      .orange-button {
        margin-top: 50px;
        color: $corange;
        border-width: 0px;
        box-shadow: 0px 0px 0px 1.5px rgba(239,128,30,1);
        &:hover {
          color: #FFF;
          background-color: $corange;
        }
        &:active {
          background-color: $corange;
          border-color: $corange;
          color: #FFF;
        }
      }
      img {
        max-height: 305px;
        border-bottom: 15px solid $cblue2;
      }
      .shadowed {
        margin: 20px 0 50px 0;
      }
    }
    .first-photo-section {
      img {
        max-height: 305px;
        border-bottom: 15px solid $cblue2;
      }
      .shadowed {
        margin: 20px auto 75px auto;
      }
    }
  }
  .text-between-sections {
    text-align: center;
    p {
      text-align: center;
      @media (max-width: 767px) {
        text-align: left;
      }
    }
    margin-top: 80px;
    @media (max-width: 991px) {
      margin-top: 40px;
    }
    @media (max-width: 767px) {
      text-align: left;
    }
    h1 {
      color: $corange;
      @media (max-width: 500px) {
        font-size: 44px;
      }
      @media (max-width: 400px) {
        font-size: 32px;
      }
    }
    .underline {
      top: 90px;
      position: absolute;
      width: 75px;
      left: 50%;
      transform: translateX(-50%);
      border-bottom: 7px solid $cblue2;
      @media (max-width: 767px) {
        position: static;
        margin-top: 20px;
        transform:none;
      }
      @media (max-width: 500px) {
        width: 60px;
        border-bottom: 6px solid $cblue2;
      }
      @media (max-width: 400px) {
        width: 50px;
        border-bottom: 5px solid $cblue2;
      }
    }
    p {
      margin: 70px auto 0 auto;
      width: 70%;
      @media (max-width: 767px) {
        margin: 70px 0 0 0;
      }
    }
  }
  .menu-section-two {
    margin-top: 80px;
    border-bottom: 1px solid rgba(239, 128, 30, 0.5);
    @media (max-width: 767px) {
      display: none;
    }
    .menu {
      display: flex;
      justify-content: space-around;
      li {
        cursor: pointer;
        font-size: 16px;
        box-sizing: border-box;
        line-height: 40px;
        opacity: 0.5;
        font-weight: 700;
        color: $corange;
        display: inline-block;
        border: 0px solid transparent;
        transition: border 0.3s, opacity 0.3s;
        &:hover {
          border-bottom: 6px solid $corange;
          opacity: 1;
          transition: border 0.3s, opacity 0.3s;
        }
        &.active {
          cursor: default;
          border-bottom: 6px solid $corange;
          opacity: 1;
        }
      }
    }
  }
  .section-two {
    padding-top: 80px;
    @media (max-width: 767px) {
      display: none;
    }
    .slick-slide {
      padding-bottom: 10px;
    }
    .text-half {
      @media (max-width: 767px) {
        margin-bottom: 40px;
      }
      p {
        margin-top: 0;
      }
      .level {
        margin-bottom: 0px;
        font-weight: 700;
      }

    }
    .image-half {
      padding-top: 10px;
      img {
        margin: 0 auto;
        border-bottom: 15px solid $cblue2;
      }
    }
  }
  .mobile-view {
    margin-top: 25px;
		padding: 15px;
		.hidde {
			display: none;
		}
		.text-half {
			margin-bottom: 40px;
			padding-left: 18px;
			@media (max-width: 400px) {
				padding-left: 5px;
			}
      .level {
        margin-top: 0;
        font-weight: 700;
      }
		}
		.row {
			margin-bottom: 50px;
			box-shadow: 0px 3px 9.7px 0.3px rgba(0, 0, 0, 0.2);
			padding: 30px;
			@media (max-width: 800px) {
				padding: 25px;
			}
			@media (max-width: 400px) {
				margin-bottom: 35px;
				padding: 15px 20px 15px 20px;
			}
			h1 {
				margin: 0;
				font-family: 'Roboto Condensed', sans-serif;
				color: $corange;
				font-size: 50px;
				font-weight: 700;
				display: inline-block;
				@media (max-width: 800px) {
					font-size: 35px;
				}
				@media (max-width: 400px) {
					font-size: 25px;
				}
			}
			img {
				transition: all 0.5s;
				margin-top: 18px;
				float: right;
				display: inline-block;
				@media (max-width: 800px) {
					margin-top: 10px;
				}
				@media (max-width: 400px) {
					max-height: 10px;
					margin-top: 9px;
				}
			}
		}
	}
  .section-three {
    padding-top: 150px;
    @media (max-width: 991px) {
      padding-top: 75px;
    }
    @media (max-width: 767px) {
      padding-top: 0;
    }
    .row {
      position: relative;
      height: 175px;
      @media (max-width: 991px) {
        background-color: transparent;
      }
      .relative-position {
        position: relative;
        @media (max-width: 991px) {
          height: 175px;
          margin-bottom: 20px;
        }
      }
      .row {
        margin-top: 0;
      }
      &.not-first {
        height: auto;
        margin-bottom: 50px;
      }
    }
    h1 {
      color: $corange;
      text-align: center;
      margin-bottom: 50px;
      @media (max-width: 767px) {
        text-align: left;
        margin-bottom: 15px;
      }
      @media (max-width: 500px) {
        font-size: 38px;
      }
      @media (max-width: 400px) {
        font-size: 32px;
      }
    }
    .underline {
      top: 90px;
      position: absolute;
      width: 75px;
      left: 50%;
      transform: translateX(-50%);
      border-bottom: 7px solid $cblue2;
      @media (max-width: 767px) {
        position: static;
        transform: none;
        top: 135px;
        margin-bottom: 40px;
      }
      @media (max-width: 500px) {
        width: 60px;
        border-bottom: 6px solid $cblue2;
      }
      @media (max-width: 400px) {
        width: 50px;
        border-bottom: 5px solid $cblue2;
      }
    }
    .tile-row {
      margin-top: 30px;
      &:first-of-type {
        margin-top: 0;
      }
      @media (max-width: 991px) {
        margin-top: 0;
      }
    }
    .card {
      height: 175px;
      width: 550px;
      position: absolute;
      text-align: left;
      cursor: pointer;
      margin-right: 5px;
      margin-left: 5px;
      display: flex;
      transition: all 0.3s;
      box-shadow: 10px 8px 39px -1px rgba(230,229,229,1);
      top: 0;
      @media (max-width: 1199px) {
        width: 460px;
      }
      @media (max-width: 991px) {
        left: 50%;
        transform: translate(-50%, 0%);
        width: 500px;
      }
      @media (max-width: 760px) {
        left: auto;
        transform: none;
      }
      @media (max-width: 560px) {
        width: 395px;
      }
      @media (max-width: 460px) {
        width: 90%
      }
      &:hover {
        transition: all 0.3s;
        top: -5px;
      }
      .no-paddings {
        background-color: white;
        @media (min-width: 992px) {
          width: 53%;
        }
        @media (max-width: 1199px) {
          width: 55%;
        }
        @media (max-width: 991px) {
          width: auto;
        }
        @media (max-width: 460px) {
          width: 100%;
        }
        padding-left: 0px;
        padding-right: 0;
        position: relative;
      }
      .image {
        position: relative;
        overflow: hidden;
        height: 175px;
        @media (min-width: 992px) {
          width: 47%;
        }
        @media (max-width: 1199px) {
          width: 45%
        }
        @media (max-width: 991px) {
          width: 45%;
        }
        @media (max-width: 560px) {
          width: 30%;
        }
        .card-image {
          position: absolute;
          top:-100%; left:0; right: 0; bottom:-100%;
          margin: auto;
          max-height: 180px;
          min-width: 260px;
        }
        padding-left: 0;
        padding-right: 0;
      }
      .text {
        padding-left: 30px;
        @media (max-width: 991px) {
          width: 300px;
        }
        @media (max-width: 560px ) {
          width: 280px;
        }
        @media (max-width: 460px) {
          padding-left: 10px;
          width: 260px;
        }
        @media (max-width: 380px) {
          width: 100%;
        }
        h3 {
          font-size: 16px;
          font-weight: 700;
          color: $corange;
        }
        h4 {
          font-size: 14px;
          font-style: italic;
        }
        p {
          width: 100%;
          font-size: 13px;
          margin-top: 10px;
          margin-bottom: 0;
          margin-left: 0;
          margin-right: 0;
        }
        span {
          font-size: 13px;
        }
      }
      .color-info {
        position: absolute;
        bottom: 0;
        background-color: $corange;
        width: 100%;
        h4 {
          font-size: 14px;
          text-align: center;
          color: white;
        }
      }
    }
  }
  .background-for-you {
    .background {
      overflow: visible;
      height: 600px;
      background-image: url("./img/home/home-background2.jpg");
      background-size: cover;
  		background-position: center bottom;
      @media (min-width: 1600px) {
        height: 750px;
      }
      @media (max-width: 991px) {
        height: 400px;
      }
      @media (max-width: 767px) {
        height: 300px;
      }
    }
    .question-stripe {
      margin-top: -1px;
      cursor: pointer;
      text-align: center;
      background-color: $corange;
      padding-top: 10px;
      padding-bottom: 15px;
      margin-bottom: 80px;
      a {
        color: #fff;
        &:hover {
          text-decoration: none;
        }
      }
      @media (max-width: 850px) {
        text-align: left;
      }
      @media (max-width: 500px) {
        margin-bottom: 50px;
      }
      h1 {
        font-size: 36px;
        font-weight: 700;
        color: #FFF;
        display: inline-block;
        @media (max-width: 850px) {
          padding-left: 20px;
        }
        @media (max-width: 560px) {
          font-size: 28px;
        }
        @media (max-width: 440px) {
          font-size: 22.5px;
        }
        @media (max-width: 350px) {
          font-size: 20px;
        }
      }
      img {
        display: inline-block;
        max-height: 35px;
        margin-bottom: 20px;
        margin-left: 40px;
        box-shadow: none;
        @media (max-width: 850px) {
          margin: 0;
          max-height: 60px;
          float: right;
          margin-top: 29px;
        }
        @media (max-width: 560px) {
          margin-top: 26px;
          max-height: 50px;
        }
        @media (max-width: 440px) {
          max-height: 35px;
        }
        @media (max-width: 350px) {
          max-height: 30px;
          margin-top: 28px;
        }
      }
      .line-break {
        display: none;
        @media (max-width: 850px) {
          display: block;
        }
      }
    }
  }
  .instagram {
    margin-top: 40px;
    a {
      box-shadow: 8px 10px 10px -4px rgba(230,229,229,1);
      &:focus, &:hover, &:active {
        color: #FFF;
        text-decoration: none;
      }
    }
  }
  .instagram-button {
    margin: 50px auto 100px auto;
    color: $cblue2;
    border: 0px;
    box-shadow: 0px 0px 0px 1.5px $cblue2;
    &:hover {
       background-color: $cblue2;
       color: #FFF;
    }
  }
  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000000;
    overflow-y: hidden;
    background-color: #000;
    opacity: 0;
    top: 0px;
  }
  .detal {
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0;
    background-color: #FFF;
    position: fixed;
    z-index: 10000001;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    .scrollable {
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }

}
