@import "_variables.scss";
@import "_footer.scss";
@import "_header.scss";
@import "_home.scss";
@import "_contact.scss";
@import "_aboutus.scss";
@import "_slick.scss";
@import "_slick-theme.scss";
@import "_for_business.scss";
@import "_for-you.scss";
@import "_detal.scss";
@import "_template.scss";


body {
  width: 100%;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  //font-family: 'Roboto Condensed', sans-serif;
  overflow-x: hidden;
}

a:focus, :hover, :active {
  text-decoration: none;
}

.disable-scroll {
  overflow: hidden;
}
