.home-page {
  overflow-x: hidden;
  padding-top: 50px;
  //global styles
  h1 {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    font-size: 60px;
  }
  h2 {
    font-family: 'Roboto Condensed', sans-serif;
    position: absolute;
    font-size: 120px;
    font-weight: 700;
    opacity: 0.05;
  }

  p {
    text-align: justify;
    line-height: 24px;
    margin-top: 30px;
    font-size: 14px;
    // &.centered-text {
    //   text-align: center;
    // }
  }
  .underline {
    position: absolute;
    width: 70px;
    height: 8px;
  }
  .shadowed {
    -webkit-box-shadow: 8px 10px 10px -4px rgba(230,229,229,1);
    -moz-box-shadow: 8px 10px 10px -4px rgba(230,229,229,1);
    box-shadow: 8px 10px 10px -4px rgba(230,229,229,1);
  }
  .btn-outline {
    border-radius: 0;
    background-color: inherit;
    color: inherit;
    transition: all .5s;
    display: block;
    font-size: 13px;
    font-weight: 700;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 8px;
    width: 205px;
    border: 0;
    &:focus {
      outline: 0;
    }
  }

  a {
    &:focus, &:hover, &:active, &:visited {
      text-decoration: none;
      color: white;
    }
  }
  //////////////////
  .background {
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    z-index: -10;
		background-image: url("./img/home/home-background1.jpg");
		height: 800px;
		background-size: cover;
		background-position: center top;
	}
  .sliding-person {
    position: absolute;
    z-index: 0;
    top: 300px;
    img {
      max-height: 450px;
    }
    @media (max-width: 991px) {
      display: none;
    }
    &.left-one {
      left: 0;
      @media (max-width: 1420px) {
        left: 50%;
        transform: translate(-50%, 0%);
        img {
          transform: translate(-87%, 0%);
        }
      }
    }
    &.right-one {
      top: 350px;
      right: 0;
      @media (max-width: 1200px) {
        left: 50%;
        transform: translate(-50%, 0%);
        img {
         transform: translate(35%, 0%);
        }
      }
      .hidde {
        display: none;
        &.helicopter {
          max-height: 163px;
          position: absolute;
          top: -260px;
          right: 100px;
          @media (max-width: 1200px) {
            right: 100px;
            transform: translate(95%,0);
            img {
              transform: translate(0,0);
            }
          }
        }
      }
    }
    &.div-left-3 {
      top: 370px;
      img {
        max-height: 405px;
      }
      @media (max-width: 1420px) {
        left: 0%;
        transform: translate(0%, 0%);
        img {
          transform: translate(0%, 0%);
        }
      }
      @media (max-width: 1300px) {
        left: 50%;
        transform: translate(-50%, 0%);
        img {
         transform: translate(-61%, 0%);
        }
      }
    }
    &.div-left-2 {
      img {
        max-height: 495px;
      }
      @media (max-width: 1475px) {
        left: 0;
        transform: none;
        img {
          transform: none;
        }
      }
      @media (max-width: 1300px) {
        left: 50%;
        transform: translate(-50%, 0%);
        img {
          transform: translate(-152%, 0%);
        }
      }
    }
    &.div-left-4 {
      top:280px;
      img {
        max-height: 495px;
      }
      @media (max-width: 1475px) {
        left: 0;
        transform: translate(0%, 0%);
        img {
          transform: translate(0%, 0%);
        }
      }
      @media (max-width: 1400px) {
        left: 50%;
        transform: translate(-50%, 0%);
        img {
          transform: translate(-67%, 0%);
        }
      }
    }
    &.div-right-2 {
      top: 300px;
      img {
        max-height: 423px;
      }
      @media (max-width: 1260px) {
        left: 50%;
        transform: translate(-50%, 0%);
        img {
         transform: translate(52%, 0%);
        }
      }
    }
    &.div-right-3 {
      top: 5px;
      img {
        max-height: 675px;
      }
      @media (max-width: 1400px) {
        left: 50%;
        transform: translate(-50%, 0%);
        img {
         transform: translate(95%, 0%);
        }
      }
      @media (max-width: 1220px) {
        left: 50%;
        transform: translate(-50%, 0%);
        img {
         transform: translate(85%, 0%);
        }
      }
    }
    &.div-right-4 {
      top: 280px;
      img {
        max-height: 495px;
      }
    }
    &.div-right-5 {
      top: 230px ;
      img {
        max-height: 540px;
      }
      @media (max-width: 1300px) {
        left: 50%;
        transform: translate(-50%, 0%);
        img {
         transform: translate(115%, 0%);
        }
      }
    }
  }
  .hero-text {
    margin-top: 200px;
    h3 {
      font-weight: 700;
      filter: drop-shadow(1px 6px 12px #666666);
      -webkit-filter: drop-shadow(1px 6px 12px #666666);
      font-size: 75px;
      text-align: center;
      color: white;
      font-family: 'Roboto Condensed', sans-serif;
      @media (max-width: 991px) {
        margin-bottom: 130px;
      }
      @media (max-width: 700px) {
        font-size: 54px;
      }
      @media (max-width: 500px) {
        font-size: 42px;
      }
      @media (max-width: 400px) {
        font-size: 32px;
      }
    }
    .buttons {
      margin-top: 45px;
      display: flex;
      justify-content: center;
      @media (max-width: 991px) {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }
      .btn-primary {
        filter: drop-shadow(1px 6px 12px #666666);
        -webkit-filter: drop-shadow(1px 6px 12px #666666);
        transition: all .5s;
        padding: 10px 30px 10px 30px;
        border-radius: 0;
        font-size: 18px;
        font-weight: 700;
        width: 180px;
        height: 45px;
        font-family: 'Roboto Condensed', sans-serif;
        span {
          filter: drop-shadow(1px 6px 12px #666666);
          -webkit-filter: drop-shadow(1px 6px 12px #666666);
        }
        &:active, &:focus {
          outline: 0;
        }
        @media (max-width: 991px) {
          filter: none;
          -webkit-filter: none;
        }
        &.for-you {
          margin-right: 22.5px;
          border-color: $corange;
          background-color: $corange;
          transition: all 0.3s;
          &:hover {
            transition: all 0.3s;
            border-color: #f19746;
            background-color: #f19746;
          }
          @media (max-width: 991px) {
            margin-right: 0px;
          }
        }
        &.for-business {
          margin-left: 22.5px;
          border-color: $cblue2;
          background-color: $cblue2;
          transition: all 0.3s;
          &:hover {
            transition: all 0.3s;
            background-color: #257acd;
            border-color: #257acd;
          }
          @media (max-width: 991px) {
            margin-top: 30px;
            margin-left: 0px;
          }
        }
      }
    }
  }
  .about-us-section {
    margin-top: 500px;
    @media (max-width: 991px) {
      margin-top: 100px;
    }
    h2 {
      right: 15px;
      top: -60px;
      color: $cblue2;
      @media (max-width: 991px) {
        right: auto;
        top: -80px;
        left: 15px;
      }
      @media (max-width: 500px) {
        font-size: 80px;
        top: -53px;
      }
      @media (max-width: 400px) {
        font-size: 55px;
      }
    }
    h1 {
      color: $cblue2;
      text-align: right;
      @media (max-width: 991px) {
        text-align: left;
        margin-bottom: 60px;
      }
      @media (max-width: 500px) {
        font-size: 50px;
      }
      @media (max-width: 400px) {
        font-size: 36px;
        margin-bottom: 50px;
      }
    }
    .underline {
      background-color: $corange;
      top: 100px;
      right: 18px;
      @media (max-width: 991px) {
        right: auto;
        left: 18px;
        top: 75px;
      }
      @media (max-width: 500px) {
        top: 65px;
        height: 7px;
        width: 60px
      }
      @media (max-width: 400px) {
        top: 45px;
        height: 5px;
        width: 50px;
      }
    }
    .left-side {
      @media (max-width: 991px) {
        display: none;
      }
      margin-top: 10px;
      img {
        border-bottom: 15px solid $cblue2;
        margin-top: 85px;
        float: right;
        max-height: 480px;
      }
    }
    .right-side {
      img {
        border-bottom: 15px solid $cblue2;
        max-height: 310px;
        margin-bottom: 90px;
        @media (max-width: 991px ) {
          margin-bottom: 10px;
        }
      }
      .btn {
        margin-top: 40px;
        width: 170px;
        box-shadow: 0px 0px 0px 1.5px $cblue2;
        color: $cblue2;
        transition: all 0.5s;
        &:hover {
          background-color: $cblue2;
          color: white;
          transition: all 0.5s;
        }
        @media (max-width: 991px) {
          margin-top: 30px;
        }
      }
    }
  }

  .infographic {
    position: relative;
		background: none;
		background-image: url("./img/counter-img.jpg");
		background-size: cover;
		background-position: center;

    @media (max-width: 991px) {
      margin-top: 1px;
    }

		.statistics {
			background: none;
			display: flex;
			height: 600px;
			align-items: center;
			justify-content: center;
			h3 {
				display: none;
			}
      @media (max-width: 991px) {
        justify-content: space-between;
      }

      .first, .second, .third {
        display: flex;
        flex-direction: column;
        &.hidde {
          display: none;
        }
      }

      .right-arrow, .left-arrow {
        display: flex;
        height: 100%;
        align-items: center;
      }

			.number {
				position: relative;
				font-size: 72px;
				color: $cblue2;
				font-weight: 700;
				font-family: 'Roboto Condensed', sans-serif;
        @media (max-width: 991px) {
          text-align: center;
        }

				&:before {
					content: "";
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					bottom: -10px;
					height: 1px;
					width: 70px;
					/* or 100px */
					border-bottom: 8px solid $corange;
				}
			}

			.count-title {
				font-family: 'Roboto Condensed', sans-serif;
				padding-top: 15px;
				font-size: 28px;
				color: $cblue2;
        @media (max-width: 991px) {
          padding-top: 20px;
          text-align: center;
        }
			}

			.statistics-inner {
				width: 100%;
				display: flex;
				justify-content: space-around;
			}
		}
    .for-you-waypoint {
      height: 1px;
      width: 10px;
      position: absolute;
      bottom: 80px;
    }
	}

  .for-you-section {
    position: relative;
    text-align: center;
    p {
      text-align: center;
      @media (max-width: 760px) {
        text-align: justify;
      }
    }
    @media (max-width: 760px) {
      text-align: justify;
    }
    h1 {
      color: $corange;
      @media (max-width: 500px) {
        font-size: 46px;
      }
      @media (max-width: 400px) {
        font-size: 36px;
      }
    }
    h2 {
      color: $corange;
      left: 50%;
      transform: translate(-50%, 0%);
      top: -45px;
      font-size: 80px;
      width: 100%;
      @media (max-width: 760px) {
        left: auto;
        transform: none;
      }
      @media (max-width: 500px) {
        font-size: 64px;
        top: -40px;
      }
      @media (max-width: 400px) {
        font-size: 50px;
        top: -35px;
      }
    }
    .underline {
      background-color: $cblue2;
      left: 50%;
      transform: translate(-50%, 0%);
      height: 7px;
      width: 68px;
      top: 85px;
      @media (max-width: 760px) {
        transform: none;
        left: auto;
      }
      @media (max-width: 500px) {
        height: 7px;
        width: 60px;
        top: 65px !important;
      }
      @media (max-width: 400px) {
        width: 50px !important;
        height: 5px !important;
        top: 50px !important;
      }
    }
    p {
      margin: 0 auto;
      width: 60%;
      margin-top: 70px;
      margin-bottom: 30px;
      @media (max-width: 760px) {
        margin: 70px 0px 30px 0px;
        width: 100%;
      }
      @media (max-width: 500px) {
        margin-top: 60px;
      }
      @media (max-width: 400px) {
        margin-top: 50px;
      }
    }
    .btn {
      width: 170px;
      box-shadow: 0px 0px 0px 1.5px $corange;
      color: $corange;
      transition: all 0.5s;
      margin: 0 auto;
      &:hover {
        background-color: $corange;
        color: white;
        transition: all 0.5s;
      }
      @media (max-width: 760px) {
        margin: 0;
      }
    }
    .nearest-events {
      margin-top: 40px;
      height: 175px;
      @media (max-width: 991px) {
        background-color: transparent;
      }
      @media (max-width: 380px) {
        margin-top: 30px;
      }
      &.margin-top {
        margin-top: 70px;
        height: auto;
      }
      .relative-position {
        position: relative;
        @media (max-width: 991px) {
          height: 175px;
          margin-bottom: 20px;
        }
      }
      .hero-h3 {
        font-size: 30px;
        font-weight: 500;
        text-align: left;
        color: $corange;
        padding-left: 5px;
        font-family: 'Roboto Condensed', sans-serif;
        @media (max-width: 380px) {
          font-size: 24px;
          margin-bottom: 0;
          margin-top: 0;
          font-style: normal;
          font-weight: 500;
        }
      }
      .text-right {
        padding-top: 11px;
        text-align: right;
        margin-top: 20px;
        padding-right: 5px;
      }
      a {
        font-size: 16px;
        font-weight: 600;
        color: $corange;
        border-bottom: 1px solid $corange;
        &:hover {
          color: $corange;
        }
      }
      .card {
        height: 175px;
        width: 550px;
        position: absolute;
        text-align: left;
        cursor: pointer;
        margin-right: 5px;
        margin-left: 5px;
        display: flex;
        transition: all 0.3s;
        box-shadow: 10px 8px 39px -1px rgba(230,229,229,1);
        top: 0;
        @media (max-width: 1199px) {
          width: 460px;
        }
        @media (max-width: 991px) {
          left: 50%;
          transform: translate(-50%, 0%);
          width: 500px;
        }
        @media (max-width: 760px) {
          left: auto;
          transform: none;
        }
        @media (max-width: 560px) {
          width: 395px;
        }
        @media (max-width: 460px) {
          width: 90%
        }
        &:hover {
          transition: all 0.3s;
          top: -5px;
        }
        .no-paddings {
          @media (min-width: 992px) {
            width: 53%;
          }
          @media (max-width: 1199px) {
            width: 55%;
          }
          @media (max-width: 991px) {
            width: auto;
          }
          @media (max-width: 460px) {
            width: 100%;
          }
          padding-left: 0px;
          padding-right: 0;
          position: relative;
        }
        .image {
          position: relative;
          overflow: hidden;
          height: 175px;
          @media (min-width: 992px) {
            width: 47%;
          }
          @media (max-width: 1199px) {
            width: 45%
          }
          @media (max-width: 991px) {
            width: 45%;
          }
          @media (max-width: 560px) {
            width: 30%;
          }
          .card-image {
            position: absolute;
            top:-100%; left:0; right: 0; bottom:-100%;
            margin: auto;
            max-height: 180px;
            min-width: 260px;
          }
          padding-left: 0;
          padding-right: 0;
        }
        .text {
          padding-left: 30px;
          @media (max-width: 991px) {
            width: 300px;
          }
          @media (max-width: 560px ) {
            width: 280px;
          }
          @media (max-width: 460px) {
            padding-left: 10px;
            width: 260px;
          }
          @media (max-width: 380px) {
            width: 100%;
          }
          h3 {
            font-size: 16px;
            font-weight: 700;
            color: $corange;
          }
          h4 {
            font-size: 14px;
            font-style: italic;
          }
          p {
            text-align: left;
            width: 100%;
            font-size: 13px;
            margin-top: 10px;
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 0;
          }
          span {
            font-size: 13px;
          }
        }
        .color-info {
          position: absolute;
          bottom: 0;
          background-color: $corange;
          width: 100%;
          h4 {
            font-size: 14px;
            text-align: center;
            color: white;
          }
        }
      }
    }
    .all-proposals {
      width: 240px;
      margin: 5px auto 0 auto;
      @media (max-width: 760px) {
        margin: 5px 0 0 0;
        margin-left: 6px;
      }
    }
  }
  .background-for-you {
    .background-image {
  		background-image: url("./img/home/home-background2.jpg");
  		height: 600px;
  		background-size: cover;
  		background-position: center bottom;
      @media (min-width: 1600px) {
        height: 750px;
      }
  		@media (max-width: 991px) {
  			height: 400px;
  		}
  		@media (max-width: 767px) {
  			height: 300px;
  		}
  	}
    .question-stripe {
      margin-top: -1px;
      cursor: pointer;
      text-align: center;
      background-color: $corange;
      padding-top: 10px;
      padding-bottom: 15px;
      margin-bottom: 80px;
      a {
        color: #fff;
        &:hover {
          text-decoration: none;
        }
      }
      @media (max-width: 850px) {
        text-align: left;
      }
      @media (max-width: 500px) {
        margin-bottom: 50px;
      }
      h1 {
        font-size: 40px;
        font-weight: 700;
        color: #FFF;
        display: inline-block;
        @media (max-width: 850px) {
          padding-left: 20px;
        }
        @media (max-width: 560px) {
          font-size: 30px;
        }
        @media (max-width: 440px) {
          font-size: 24px;
        }
        @media (max-width: 350px) {
          font-size: 20px;
        }
      }
      img {
        display: inline-block;
        max-height: 35px;
        margin-bottom: 20px;
        margin-left: 40px;
        box-shadow: none;
        @media (max-width: 850px) {
          margin: 0;
          max-height: 60px;
          float: right;
          margin-top: 29px;
          margin-right: 15px;
        }
        @media (max-width: 560px) {
          margin-top: 26px;
          max-height: 50px;
        }
        @media (max-width: 440px) {
          max-height: 35px;
        }
        @media (max-width: 350px) {
          max-height: 30px;
          margin-top: 28px;
        }
      }
      .line-break {
        display: none;
        @media (max-width: 850px) {
          display: block;
        }
      }
    }
  }
  .for-business-section {
    position: relative;
    h2 {
      font-size: 70px;
      left: 15px;
      top: -25px;
      color: $cblue2
    }
    h1 {
      color: $cblue2;
    }
    .underline {
      background-color: $corange;
      top: 100px;
      left: 18px;
    }
    .for-business-waypoint {
      width: 10px;
      height: 1px;
      position: absolute;
      top:-90px;
    }
    margin-top: 120px;
    @media (max-width: 991px) {
      margin-top: 0;
    }
    .right-side {
      @media (max-width: 991px) {
        display: none;
      }
      margin-top: 10px;
      img {
        border-bottom: 15px solid $cblue2;
        margin-top: 90px;
        max-height: 570px;
      }
    }
    .left-side {
      padding-top: 20px;
      direction: rtl;
      .careless-dot {
        float: right;
        @media (max-width: 991px) {
          float: none;
        }
      }
      @media (max-width: 991px) {
        text-align: left;
        direction: ltr;
      }
      img {
        border-bottom: 15px solid $cblue2;
        max-height: 320px;
        margin-bottom: 90px;
        @media (max-width: 991px) {
          margin-bottom: 25px;
        }
      }
      .btn {
        float: right;
        margin-top: 30px;
        width: 170px;
        box-shadow: 0px 0px 0px 1.5px $cblue2;
        color: $cblue2;
        transition: all 0.5s;
        &:hover {
          background-color: $cblue2;
          color: white;
          transition: all 0.5s;
        }
        @media (max-width: 991px) {
          float: left;
          margin-top: 25px;
        }
      }
      h1 {
        margin-bottom: 60px;
        @media (max-width: 500px) {
          font-size: 46px;
          margin-bottom: 56px;
        }
        @media (max-width: 400px) {
          font-size: 36px;
          margin-bottom: 40px;
        }
      }
      h2 {
        font-size: 84px;
        top: -10px;
        @media (max-width: 500px) {
          font-size: 64px;
          top: -3px;
        }
        @media (max-width: 400px) {
          font-size: 50px;
          top: 1px;
        }
      }
      .underline {
        @media (max-width: 400px) {
          width: 50px !important;
          height: 5px !important;
          top: 83px !important;
        }
        @media (max-width: 500px) {
          height: 7px;
          width: 60px;
          top: 100px;
        }
        top: 115px;
      }
    }
  }
  .question-stripe {
    a {
      color: #fff;
      &:hover {
        text-decoration: none;
      }
    }
    margin-top: 125px;
    @media (max-width: 991px) {
      margin-top: 50px;
    }
    cursor: pointer;
    text-align: center;
    background-color: $cblue2;
    padding-top: 10px;
    padding-bottom: 15px;
    margin-bottom: 80px;
    @media (max-width: 850px) {
      text-align: left;
    }
    h1 {
      font-size: 40px;
      font-weight: 700;
      color: #FFF;
      display: inline-block;
      @media (max-width: 850px) {
        padding-left: 20px;
      }
      @media (max-width: 560px) {
        font-size: 30px;
      }
      @media (max-width: 440px) {
        font-size: 24px;
      }
      @media (max-width: 350px) {
        font-size: 20px;
      }
    }
    img {
      display: inline-block;
      max-height: 35px;
      margin-bottom: 20px;
      margin-left: 40px;
      box-shadow: none;
      @media (max-width: 850px) {
        margin: 0;
        max-height: 60px;
        float: right;
        margin-top: 29px;
        margin-right: 15px;
      }
      @media (max-width: 560px) {
        margin-top: 26px;
        max-height: 50px;
      }
      @media (max-width: 440px) {
        max-height: 35px;
      }
      @media (max-width: 350px) {
        max-height: 30px;
        margin-top: 28px;
      }
    }
    .line-break {
      display: none;
      @media (max-width: 850px) {
        display: block;
      }
    }
  }
  .detal {
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0;
    background-color: #FFF;
    position: fixed;
    z-index: 10000001;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    .scrollable {
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
}
