.aboutUs {
	margin-top: 50px;

	.overlay {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 10000020;
		overflow-y: hidden;
		background-color: #000;
		opacity: 0.1 !important;
		top: 0;
	}

	.we {
		padding-top: 140px;
		@media (max-width: 991px) {
			padding-top: 100px;
		}
		@media (max-width: 500px) {
			padding-top: 70px;
		}
		@media (max-width: 400px) {
			padding-top: 50px;
		}
		.text-col {
			padding-top: 50px;
		}

		h1 {
			margin-top: 0;
			font-family: 'Roboto Condensed', sans-serif;
			font-weight: 700;
			color: $cblue2;
			font-size: 60px;
			@media (max-width: 500px) {
				font-size: 50px;
			}
			@media (max-width: 400px) {
				font-size: 32px;
			}
		}

		h2 {
			font-family: 'Roboto Condensed', sans-serif;
			position: absolute;
			font-size: 120px;
			font-weight: 700;
			opacity: 0.05;
			top: -30px;
			color: $cblue2;
			@media (max-width: 500px) {
        font-size: 80px;
				top: 0px;
      }
      @media (max-width: 400px) {
        font-size: 50px;
				top: 10px;
      }
		}

		.underline {
			width: 70px;
			height: 8px;
			background-color: $corange;
			margin: 20px auto 40px 0;
			@media (max-width: 500px) {
				height: 7px;
				width: 60px;
			}
			@media (max-width: 400px) {
				margin: 15px auto 40px 0;
				height: 5px;
				width: 50px;
			}
		}

		img {
			box-shadow: 6px 10px 27.84px 4.16px rgba(0, 0, 0, 0.17);
			margin-bottom: 75px;
			max-height: 305px;
			border-bottom: 15px solid $cblue2;
		}
		.text {
			p {
				text-align: justify;
				line-height: 24px;
			}

			.textlead {
				margin-top: 60px;
			}
		}

		.photos {
			//padding-top: 35px;
			img {
				margin-top: 35px;
				box-shadow: 6px 10px 27.84px 4.16px rgba(0, 0, 0, 0.17);
				border-bottom: 15px solid $cblue2;
				margin-bottom: 40px;
				max-height: 305px;
				float: right;
				@media (max-width: 991px) {
					float: none;
					margin: 20px 0 0 0;
				}
			}
		}
	}

	.infographic {
    position: relative;
		background: none;
		background-image: url("./img/counter-img.jpg");
		background-size: cover;
		background-position: center;

    @media (max-width: 991px) {
      margin-top: 1px;
    }

		.statistics {
			background: none;
			display: flex;
			height: 600px;
			align-items: center;
			justify-content: center;
			h3 {
				display: none;
			}
      @media (max-width: 991px) {
        justify-content: space-between;
				padding: 0;
      }

      .first, .second, .third {
        display: flex;
        flex-direction: column;
        &.hidde {
          display: none;
        }
      }

      .right-arrow, .left-arrow {
        display: flex;
        height: 100%;
        align-items: center;
      }

			.number {
				position: relative;
				font-size: 72px;
				color: $cblue2;
				font-weight: 700;
				font-family: 'Roboto Condensed', sans-serif;
        @media (max-width: 991px) {
          text-align: center;
        }

				&:before {
					content: "";
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					bottom: -10px;
					height: 1px;
					width: 70px;
					/* or 100px */
					border-bottom: 8px solid $corange;
				}
			}

			.count-title {
				font-family: 'Roboto Condensed', sans-serif;
				padding-top: 15px;
				font-size: 28px;
				color: $cblue2;
        @media (max-width: 991px) {
          padding-top: 20px;
          text-align: center;
        }
			}

			.statistics-inner {
				width: 100%;
				display: flex;
				justify-content: space-around;
			}
		}
    .for-you-waypoint {
      height: 1px;
      width: 10px;
      position: absolute;
      bottom: 80px;
    }
	}

	.ourteam {
		padding-bottom: 100px;

		h1 {
			text-align: center;
			font-family: 'Roboto Condensed', sans-serif;
			font-weight: 700;
			color: $cblue2;
			font-size: 48px;
			@media (max-width: 400px) {
				font-size: 32px;
			}
		}

		.underline {
			width: 70px;
			height: 8px;
			background-color: $corange;
			margin: 20px auto 0;
			@media (max-width: 400px) {
				margin: 15px auto 0;
				height: 5px;
				width: 50px;
			}
		}

		.section1 {
			&.el-presidentos {
				margin-top: 70px;
				display: flex;
				justify-content: center;
				margin-bottom: 20px;
				@media (max-width: 610px) {
					flex-direction: column;
				}
			}

			.owner-box {
				max-width: 240px;
				text-align: center;
				cursor: pointer;
				margin: 0 50px;
				@media (max-width: 610px) {
					margin: 30px auto;
					max-width: 60%;
				}
				@media (max-width: 400px) {
					max-width: 70%
				}
				img {
					margin: 0 auto;
					box-shadow: 6px 10px 27.84px 4.16px rgba(0, 0, 0, 0.05);
				}

				.owner-text {
					box-shadow: 6px 10px 27.84px 4.16px rgba(0, 0, 0, 0.05);
					padding-top: 20px;
					padding-bottom: 10px;
				}

				h4 {
					color: $cblue2;
					font-weight: 700;
					font-size: 22px;
					margin-top: 0;
					margin-bottom: 5px;
				}

				p {
					color: #484645;
					font-weight: 700;
					font-size: 16px;
				}
			}
		}

		.border-class {
			margin-top: 60px;
			border-top: 1px solid transparent;
			margin-bottom: 30px;
		}

		.section2 {
			float: none;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			.employees-row {
				@media (max-width: 767px) {
					padding-left: 135px;
					padding-right: 135px;
				}
				@media (max-width: 610px) {
					padding-left: 0px;
					padding-right: 0px;
				}
			}

			.employee-box {
				margin-top: 30px;
				text-align: center;
				@media (max-width: 767px) {
					padding-left: 15px;
					padding-right: 15px;
				}
				@media (max-width: 610px) {
					padding-left: 15px;
					padding-right: 15px;
				}

				img {
					max-width: 100%;
					box-shadow: 6px 10px 27.84px 4.16px rgba(0, 0, 0, 0.05);
				}

				h4 {
					color: $cblue2;
					font-weight: 700;
					font-size: 16px;
					margin-bottom: 5px;
					margin-top: 20px;
				}

				p {
					color: #484645;
					font-weight: 700;
					font-size: 14px;
				}
			}
		}
	}

	.directions {
		h1 {
			text-align: center;
			font-family: 'Roboto Condensed', sans-serif;
			font-weight: 700;
			color: $cblue2;
			font-size: 48px;
			@media (max-width: 400px) {
				font-size: 32px;
			}
		}

		.underline {
			width: 70px;
			height: 8px;
			background-color: $corange;
			margin: 20px auto;
			@media (max-width: 400px) {
				margin: 15px auto;
				height: 5px;
				width: 50px;
			}
		}

		p {
			display: block;
			text-align: center;
			padding-top: 40px;
			margin-bottom: 90px;
			line-height: 24px;
		}
	}

	.aboutus-slider {
		height: 280px;
		@media (max-width: 1199px) {
			height: 250px;
		}
		@media (max-width: 991px) {
			height: 220px;
		}
		@media (max-width: 677px) {
			height: auto;
	  }
		.country {
			transition: transform 0.5s;
			cursor: pointer;
			@media (min-width: 676px) {
				&:hover {
					transition: transform 0.5s;
					transform:translateY(-10px);
					.txt {
						box-shadow: 6px 10px 27.84px 4.16px rgba(0, 0, 0, 0.14);
						transition: all 0.5s;
					}
				}
			}
		}

		.slick-track {
			padding-top: 10px;
			padding-bottom: 30px;
			@media (max-width: 500px) {
				padding-bottom: 50px;
			}
		}

		.slick-active {
			.txt {
				transition: all 0.3s;
				//box-shadow: 5px 5px 39px -10px rgba(113,111,110,0.75);
				box-shadow: 0px 11px 27.84px 4.16px rgba(0, 0, 0, 0.05);
			}
		}

		.slick-dots {
			@media (max-width: 677px) {
				bottom: -40px;
			}
		}

		button.slick-next {
			background: transparent;
			right: -50px;
			border: transparent;
			@media (max-width: 1260px) {
				right: -25px;
			}
			@media (max-width: 1199px) {
				right: -50px;
			}
			@media (max-width: 1060px) {
				right: -20px;
			}
			@media (max-width: 991px) {
				right: -50px;
			}
			@media (max-width: 840px) {
				right: -20px;
			}
			&::before {
				content: url(./img/rightArrow.png);
			}
		}
		button.slick-prev {
			left: -50px;
			background: transparent;
			border: transparent;
			@media (max-width: 1260px) {
				left: -25px;
			}
			@media (max-width: 1199px) {
				left: -50px;
			}
			@media (max-width: 1060px) {
				left: -20px;
			}
			@media (max-width: 991px) {
				left: -50px;
			}
			@media (max-width: 840px) {
				left: -20px;
			}
			&::before {
				content: url(./img/leftArrow.png);
			}
		}

		.txt {
			transition: all 0.3s;
			padding-top: 15px;
			padding-bottom: 15px;
			text-align: center;

			h1 {
				font-family: 'Open Sans', sans-serif;
				margin-top: 0;
				color: $cblue2;
				font-size: 16px;
				font-weight: 700;
				margin-bottom: 0;
			}

			p {
				margin-bottom: 0;
				padding: 0;
				font-size: 14px;
				@media (max-width: 991px) {
					width: 150px;
					margin: 0 auto;
				}
				@media (max-width: 768px) {
					width: auto;
				}
			}
		}
	}

	.licences {
		margin-top: 130px;
		padding-bottom: 150px;
		@media (max-width: 991px) {
			margin-top: 150px;
		}
		@media (max-width: 767px) {
			padding-bottom: 50px;
			margin-top: 100px;
		}
		p {
			display: block;
			text-align: center;
			padding: 40px 0;
			line-height: 24px;
		}

		h1 {
			text-align: center;
			font-family: 'Roboto Condensed', sans-serif;
			font-weight: 700;
			color: $cblue2;
			font-size: 48px;
			@media (max-width: 400px) {
				font-size: 32px;
			}
		}

		.underline {
			width: 70px;
			height: 8px;
			background-color: $corange;
			margin: 20px auto 10px;
			@media (max-width: 400px) {
				margin: 15px auto 10px;
				height: 5px;
				width: 50px;
			}
		}

		.partner-logos {
			margin-top: 40px;
			display: flex;
			@media (max-width: 767px) {
				flex-direction: column;
				align-items: center;
			}

			.partner-logo {
				display: flex;
				text-align: center;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				.double-logo {
					display: flex;
					justify-content: center;
					img {
						margin: 0 5px;
					}
				}
				img {
					max-height: 110px;
					max-width: 263px;
					margin: auto;
					&.fisher-logo {
						max-width: 100%;
						@media (max-width: 767px) {
							max-width: 263px;
						}
					}
				}

				p {
					padding-top: 20px;
					color: $cblue2;
					font-family: 'Roboto Condensed', sans-serif;
					font-weight: 700;
					font-size: 18px;
				}

			}
		}

		.partner-description {
			.partner-desc {
				p {
					padding-top: 20px;
					color: $cblue2;
					font-family: 'Roboto Condensed', sans-serif;
					font-weight: 700;
					font-size: 18px;
				}
			}
		}
	}

	.partners {
		padding-bottom: 150px;
		@media (max-width: 767px) {
			padding-bottom: 70px;
		}

		h1 {
			text-align: center;
			font-family: 'Roboto Condensed', sans-serif;
			font-weight: 700;
			color: $cblue2;
			font-size: 48px;
			@media (max-width: 400px) {
				font-size: 32px;
			}
		}

		.underline {
			width: 70px;
			height: 8px;
			background-color: $corange;
			margin: 20px auto 60px;
			@media (max-width: 400px) {
				margin: 15px auto 60px;
				height: 5px;
				width: 50px;
			}
		}
		.partner-logos {
			display: flex;
			align-items: center;
			@media (max-width: 991px) {
				flex-wrap: wrap;
			}
			.partner-logo {
				display: flex;
				justify-content: center;
				align-items: center;
				@media (max-width: 767px) {
					margin-top: 20px;
					margin-bottom: 20px
				}
			}
			img {
				@media (max-width: 767px) {
					margin: 0 auto;
				}
			}
		}
	}

	.owner1-details,
	.owner2-details {
		width: 900px;
		overflow-y: hidden;
		overflow-x: hidden;
		opacity: 0;
		background-color: #FFF;
		position: fixed;
		z-index: 100000221;
		left: 50%;
		top: 50%;
		height: 480px;
		transform: translate(-50%,-50%);
		@media (max-width: 991px) {
			width: 100%;
			position: fixed;
			z-index: 100000221;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			transform: none;
			overflow-y: scroll;
			margin: 0;
			height: 100%;
		}
		.no-paddings {
			padding: 0;
			box-shadow: 5px 1px 30px 5px rgba(230,229,229,1);
			@media (max-width: 991px) {
				padding-left: 15px;
				padding-right: 15px;
			}
		}

		.text-div {
			padding-left: 50px;
			padding-top: 60px;
			padding-bottom: 30px;
			@media (max-width: 600px) {
				padding-left: 25px;
				padding-right: 0;
				padding-bottom: 0;
			}

			.owner-picture {
				box-shadow: 0px 11px 27.84px 4.16px rgba(0, 0, 0, 0.05);
				display: inline-block;
				max-width: 170px;
				@media (max-width: 600px) {
					max-width: 100px;
				}
			}

			.exit {
				position: absolute;
				right: 20px;
				top: 20px;
				max-height: 20px;
				cursor: pointer;
			}

			h1 {
				color: $cblue2;
				font-size: 30px;
				font-weight: 700;
				@media (max-width: 500px) {
					font-size: 24px;
				}
				@media (max-width: 400px) {
					font-size: 18px;
				}
			}

			h2 {
				margin-top: 10px;
				font-weight: 700;
				font-size: 16px;
			}

			.owner-name {
				@media (max-width: 991px) {
					display: inline-block;
					padding-left: 20px;
				}
				@media (max-width: 400px) {
					padding-left: 10px;
				}
			}

			p {
				margin-left: 10px;
				margin-top: 30px;
				font-size: 14px;
				line-height: 24px;
				margin-right: 50px;
				margin-bottom: 30px;
				@media (max-width: 991px) {
					line-height: 20px;
				}
			}

			a {
				color: $cblue2;
				display: block;
				font-weight: 700;
				margin-top: 10px;
			}
		}
	}

	.country-detail {
		width: 900px;
		overflow-y: hidden;
		overflow-x: hidden;
		opacity: 0;
		background-color: #FFF;
		position: fixed;
		z-index: 100000221;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		padding: 50px 80px;
		@media (max-width: 991px) {
			width: 100%;
			position: fixed;
			z-index: 100000221;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			transform: none;
			overflow-y: scroll;
		}
		@media (max-width: 767px) {
			padding: 20px 40px;
		}
		h1 {
			margin-top: 30px;
			font-weight: 700;
			color: $cblue2;
			font-size: 48px;
			font-family: 'Roboto Condensed', sans-serif;
			@media (max-width: 767px) {
				text-align: center;
			}
			@media (max-width: 400px) {
				font-size: 32px;
			}
		}

		.underline {
			width: 60px;
			height: 5px;
			background-color: $corange;
			margin: 15px 0 40px;
			@media (max-width: 767px) {
				margin: 15px auto 40px auto;
			}
			@media (max-width: 400px) {
				height: 5px;
				width: 50px;
			}
		}
		.description {
			@media (max-width: 767px) {
				text-align: center;
				margin-bottom: 20px;
			}
		}

		.exit {
			cursor: pointer;
			position: absolute;
			right: 20px;
			top: 20px;
			max-height: 20px;
		}

		.detail-img {
			max-height: 250px;
			box-shadow: 5px 1px 30px 5px rgba(230,229,229,1);
			@media (max-width: 991px) {
				float: right;
			}
			@media (max-width: 767px) {
				float: none;
				margin: 0 auto;
			}
		}

		p {
			font-size: 16px;
			line-height: 24px;
		}

		.flexbox {
			margin-top: 40px;
			padding-left: 20px;
			display: flex;
			justify-content: space-between;
			@media (max-width: 600px) {
				flex-direction: column;
			}
		}

		.plain-text {
			position: relative;
			max-width: 150px;
			@media (max-width: 600px) {
				max-width: 100%;
				margin-top: -10px;
			}

			p {
				max-width: 150px;
				font-size: 16px;
				@media (max-width: 600px) {
					max-width: 100%;
				}
			}

			.hyphen-before {
				position: absolute;
				height: 12px;
				width: 9px;
				left: -25px;
				border-bottom: 3px solid $cblue2;
			}
		}
	}
}
