.template-site {

  // GLOBAL STYLING/////////////////////////////
  h1 {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    font-size: 40px;
    color: $cblue2;
    @media (max-width: 400px) {
      font-size: 32px;
    }
  }
  h2 {
    font-weight: 700;
    font-size: 18px;
    margin-top: 10px;
  }
  h3 {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    color: $cblue2;
  }
  .underline {
    width: 50px;
    height: 5px;
    background-color: $corange;
  }
  .arrow {
    display: inline-block;
  }
  p {
    line-height: 26px;
    font-size: 16px;
    margin-bottom: 5px;
  }
  span {
    display: block;
    margin-top: 50px;
    color: #716f6e;
  }
  a {
    color: $cblue2;
    font-weight: 700;
    margin-right: 30px;
    @media (max-width: 450px) {
      display: block;
      margin-top: 10px;
    }
    &:hover, &:active, &:focus {
      outline: none;
      text-decoration: none;
    }
    img {
      margin-left: 5px;
    }
  }
  //////////////////////////////////////////////

  header {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    color: white;
    background-color: $cblue;
    border-bottom: 7px solid $corange;
    z-index: 10000001;
    box-shadow: 0px 11px 27.84px 4.16px rgba(51, 51, 51, 0.2);
    transition: top 0.2s ease-in-out;
    height: 82px;
    h1 {
      font-size: 25px;
      color: white;
      @media (max-width: 500px) {
        font-size: 18px;
      }
    }
  }
  .logo {
    margin-top: 50px;
    height: 500px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    @media (max-width: 767px) {
      height: auto;
    }
  }
  .navigation {
    padding-left: 60px;
    margin-top: 75px;
    margin-bottom: 100px;
    border-left: 5px solid $corange;
    h3 {
      cursor: pointer;
    }
    @media (max-width: 400px) {
      padding-left: 20px;
      h3 {
        font-size: 20px;
      }
    }
  }
  .first-section {
    p {
      margin-bottom: 10px;
    }
  }
  .second-section {
    margin-top: 100px;
    .underline {
      margin-bottom: 40px;
    }
    .events-dates {
      h3 {
        font-family: 'Open Sans', sans-serif;
        font-size: 24px;
        margin-bottom: 30px;
        margin-top: 30px;
      }
      .time {
        text-align: right;
        font-weight: 700;
      }
    }
    .additional-info {
      h2 {
        margin-bottom: 25px;
        margin-top: 35px;
      }
      .hyphen {
        display: inline-block;
        margin-right: 16px;
        margin-left: 0;
      }
      .arrow {
        padding-bottom: 1px;
      }
    }
    .third-section {
      margin-top: 100px;
    }
    .fourth-section {
      margin-top: 100px;
    }
  }
  .fifth-section {
    margin-top: 100px;
    background-color: $cblue2;
    h1 {
      margin-top: 60px;
      color: white;
    }
    p {
      margin-top: 50px;
      color: white;
    }
    .btn-outline {
      margin-top: 20px;
      margin-bottom: 30px;
      border-radius: 0;
      background-color: $corange;
      color: white;
      transition: all .5s;
      display: block;
      font-size: 13px;
      font-weight: 700;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 9px;
      padding-bottom: 9px;
      width: 205px;
      border: 0;
      &:focus {
        outline: 0;
      }
    }
  }
  .sixth-section {
    margin-top: 100px;
    h4 {
      font-size: 20px;
      font-weight: 700;
      margin-top: 50px;
    }
    h2 {
      margin-top: 40px;
    }
    .position-table {
      margin-bottom: 70px;
      .bold {
        font-weight: 700;
      }
      .table-header {
        margin-top: 25px;
        margin-bottom: 15px;
        p {
          font-weight: 700;
        }
      }
    }
    footer {
      margin-top: 100px;
      border-top: 1px solid $cblue2;
      h2 {
        a {
          margin-right: 0;
        }
        text-align: center;
      }
      .icons {
        margin-top: 45px;
        display: flex;
        justify-content: center;
        margin-bottom: 45px;
        img {
          margin-left: 15px;
          margin-right: 15px;
          max-height: 40px;
        }
      }
    }
  }
}
