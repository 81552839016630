.detal-site {
  .arrow {
    display: inline-block;
    max-height: 10px;
  }
  .boxes {
    margin-top: 5px;
    margin-bottom: 10px;
    div {
      display: inline-block;
      height: 12px;
      width: 25px;
      background-color: $cdarkgrey;
      &.lv0 {
        background-color: $cgrey;
      }
      &.lv1 {
        background: linear-gradient(45deg, rgba(0,200,83,1) 0%, rgba(86,200,53,1) 100%);
      }
      &.lv2 {
        background: linear-gradient(45deg, rgba(100,200,48,1) 0%, rgba(211,200,11,1) 100%);
      }
      &.lv3 {
        background: linear-gradient(45deg, rgba(222,200,8,1) 0%, rgba(255,170,4,1) 100%);
      }
      &.lv4 {
        background: linear-gradient(45deg, #ffa207 0%, #f76421 100%);
      }
      &.lv5 {
        background: linear-gradient(45deg, #f45e24 0%, #e53935 100%);
      }
    }
  }
  .header {
    padding-top: 15px;
    padding-bottom: 13px;
    color: white;
    background-color: $corange;
    font-weight: 700;
    &.altColor {
      background-color: $cblue2
    }
    .exit {
      @media (max-width: 767px) {
        margin-top: 10px;
      }
      img {
        cursor: pointer;
        float:right;
      }
    }
  }
  .hero-text {
    margin-top: 80px;
    text-align: center;
    color: $corange;
    position: relative;
    &.altColor {
      color: $cblue2;
      .underline {
        border-bottom: 7px solid $cblue2;
      }
    }
    h1 {
      font-family: 'Open Sans', sans-serif;
      font-size: 48px;
      @media (max-width: 500px) {
        font-size: 40px;
      }
      @media (max-width: 400px) {
        font-size: 32px;
      }
    }
    h2 {
      font-family: 'Open Sans', sans-serif;
      margin-top: 0;
      font-size: 28px;
      opacity: 1;
      width: 100%;
      display: block;
      @media (max-width: 500px) {
        font-size: 20px;
      }
      @media (max-width: 400px) {
        font-size: 16px;
      }
    }
    .underline {
      top: 115px;
      position: absolute;
      width: 75px;
      left: 50%;
      transform: translateX(-50%);
      border-bottom: 7px solid $cblue2;
      @media (max-width: 500px) {
        border-bottom: 6px solid $cblue2;
        top: 95px;
      }
      @media (max-width: 400px) {
        border-bottom: 5px solid $cblue2;
        top: 80px;
      }
    }
  }
  .slider {
    margin-top: 120px;
    .slick-slide {
      margin-right: 10px;
      margin-left: 10px;
    }
    .slick-track {
      padding-bottom: 10px;
    }
    .slick-dots {
      li {
        width: 12px;
        margin-right: 2.5px;
        margin-left: 2.5px;
      }
      bottom: -35px;
      button {
        border: 1px solid $corange;
        &:hover, &:focus, &:before {
          background-color: $corange;
          &:before {
            background-color: $corange;
          }
        }
      }
    }
    .slick-arrow {
      background-color: $corange;
      border-color: $corange;
      padding-bottom: 2px;
    }
    .slick-active {
      button {
        background-color: $corange;
      }
    }
    &.altColor {
      button {
        border: 1px solid $cblue2;
        &:hover, &:focus, &:before {
          background-color: $cblue2;
          &:before {
            background-color: $cblue2;
          }
        }
      }
      .slick-arrow {
        background-color: $cblue2;
        border-color: $cblue2;
        padding-bottom: 2px;
      }
      .slick-active {
        button {
          background-color: $cblue2;
        }
      }
    }
  }
  .center-margins {
    padding-bottom: 80px;
    border-bottom: 1px solid #c6c5c5;
    margin: 0 auto;
    margin-top: 60px;
    .info-header {
      font-size: 12px;
      color: #979695;
      margin-bottom: 0;
      &:last-of-type {
        color: black;
        font-weight: 700;
      }
    }
    .info-paragraph {
      font-size: 17px;
      margin-top: 0;
      font-weight: 700;
      a {
        color: black;
        &:hover, &:active, &:visited, &:focus {
          color: black;
        }
      }
    }
    .info-text {
      margin-bottom: 30px;
    }
    a {
      font-weight: 700;
     &:hover, &:focus, &:active &:visited {
       text-decoration: none;
     }
     @media (max-width: 991px) {
       display: block;
     }
    }
    .weather {
      color: $corange;
      margin-right: 50px;
      @media (max-width: 991px) {
        margin-right: 0px;
      }
    }
    .route {
      color: $cblue2;
      @media (max-width: 991px) {
        margin-top: 20px;
      }
    }
  }
  .info-section {
    &.altColor {
      h1 {
        color: $cblue2;
      }
      .underline {
        border-bottom: 7px solid $cblue2;
      }
      .section-part {
        h1 {
          @media (max-width: 500px) {
            font-size: 32px;
          }
        }
        .events-dates {
          h3 {
            color: $cblue2;
          }
        }
      }
    }
    margin: 0 auto;
    .section-part {
      position: relative;
      .events-dates {
        margin-top: 20px;
        h3 {
          font-weight: 700;
          color: $corange;
        }
        .time {
          text-align: right;
          font-weight: 700;
          @media (max-width: 400px) {
            padding-right: 0;
          }
        }
        p {
          margin: 2.5px;
        }
      }
      h3 {
        font-family: 'Roboto Condensed', sans-serif;
        margin-top: 30px;
        font-weight: 700;
        color: $cblue2;
        font-size: 16px;
      }
      p {
        margin-top: 0;
      }
    }
    .underline {
      top: 55px;
      position: absolute;
      width: 55px;
      border-bottom: 7px solid $cblue2;
      @media (max-width: 767px) {
        position: static;
        margin-bottom: 20px;
        border-bottom: 6px solid $cblue2;
      }
      @media (max-width: 500px) {
        border-bottom: 5px solid $cblue2;
      }
    }
    h1 {
      margin-top: 100px;
      font-size: 38px;
      color: $corange;
      margin-bottom: 60px;
      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
      @media (max-width: 500px) {
        font-size: 32px;
      }
    }
  }
  .footer {
    &.altColor {
      background-color: $cblue2;
    }
    margin-top: 80px;
    text-align: center;
    font-family: 'Roboto Condensed', sans-serif;
    background-color: $corange;
    padding-top: 10px;
    padding-bottom: 15px;
    color: #FFF;
    a {
      color: #FFF;
      &:hover {
        text-decoration: none;
      }
    }
    h1 {
      font-size: 24px;
      font-weight: 400;
      color: #FFF;
      display: inline-block;
      @media (max-width: 500px) {
        font-size: 18px;
      }
      @media (max-width: 400px) {
        font-size: 14px;
      }
      span {
        font-weight: 700;
      }
    }
  }
}
