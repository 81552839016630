.contact{
	margin-top: 82px;
	p {
		margin-bottom: 5px;
	}

	.row-contact{
		padding-top:10px;
	}

	.contact-header{
		h3{
			font-size: 24px;
			font-family: 'Roboto Condensed', sans-serif;
			font-weight: 600;
			padding-top:50px;
			color:$corange;
		}

		.underline{
			width: 45px;
			height: 5px;
			background-color:$cblue2;
			/*margin:0px 0px 0px auto;*/
		}
	}

	.person{
		font-size: 14px;
		&.firm-data {
			margin-top: 10px;
			line-height: 24px;
		}


		h4{

			margin-top: 30px;
			font-size: 14px;
			font-weight:700;
		}
		a {
			color: black;
			&:hover {
				text-decoration: none;
			}
		}
	}
	.line{
		padding-top:50px;
		border:$cgrey;
		border-style:solid;
		border-width:0px 0px 1px 0px;
	}

	.col-xs-10{
		float:none;
	}


	.bottom-contact{
		padding-bottom:50px;
		position:relative;
		/*background-image: url("./img/trees.png");*/

		img{
			position:absolute;
			max-height:450px;
			width:auto;
			bottom:0px;
			right:-60px;
		}

		.bottom-contact-container{
			display:block;
			padding-bottom:50px;
			/*display:table;
			margin-left: 20%;
			margin-right: 20%;
			width: 60%;*/


			.contact-header{
				/*padding-right: 20px;
				padding-left: 20px;*/
				h3{
					font-size: 24px;
					font-family: 'Roboto Condensed', sans-serif;
					font-weight: 600;
					padding-top:50px;
					color:$cblue2;
				}

				.underline{
					width: 45px;
					height: 5px;
					background-color:$corange;
					/*margin:0px 0px 0px auto;*/
				}
			}
		}
	}


	.mobile-view {
		padding: 15px;
		.hidde {
			display: none;
		}
		.person {
			margin-bottom: 40px;
			padding-left: 18px;
			@media (max-width: 400px) {
				padding-left: 5px;
			}
			p {
				font-weight: 700;

			}
			a {
				display: block;
				color: black;
				margin-bottom: 5px;
			}
			span {
				margin-bottom: 5px;
				display: block;
			}
		}
		.row {
			margin-bottom: 50px;
			box-shadow: 0px 3px 9.7px 0.3px rgba(0, 0, 0, 0.2);
			padding: 30px;
			@media (max-width: 800px) {
				padding: 25px;
			}
			@media (max-width: 400px) {
				margin-bottom: 35px;
				padding: 15px 20px 15px 20px;
			}
			h1 {
				margin: 0;
				font-family: 'Roboto Condensed', sans-serif;
				color: $corange;
				font-size: 50px;
				font-weight: 700;
				display: inline-block;
				@media (max-width: 800px) {
					font-size: 35px;
				}
				@media (max-width: 400px) {
					font-size: 25px;
				}
			}
			img {
				transition: all 0.5s;
				margin-top: 18px;
				float: right;
				display: inline-block;
				@media (max-width: 800px) {
					margin-top: 10px;
				}
				@media (max-width: 400px) {
					max-height: 10px;
					margin-top: 9px;
				}
			}
			&.alt-color {
				h1 {
					color: $cblue2;
				}
			}
		}
	}



}
